import { AnalyticsBrowser } from '@segment/analytics-next';

import { HighlightType } from 'global/api/controller/AtomController';
import { Source } from 'global/api/controller/EnterpriseSearchController';
import config from 'global/config';
import {
  AnalyticsWidgetConfig,
  GroupType,
  WidgetType,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { LoginMethod } from 'screens/platform/cross-platform-components/context/user/UserActions';
import { PopulatedUserState } from 'screens/platform/cross-platform-components/context/user/UserContext';
import { ExportEntityType } from 'screens/platform/directory/components/ExportTableButton';
import DebuggerConsole from 'utils/DebuggerConsole';

const { segmentWriteKey } = config;

export type RequestTagLocation = 'Tags Master Filter Search' | 'Search Bar' | 'Interactions Table'

// To add a new event:
// 1. Add its name to the AnalyticsEvent enum - name must be in Proper Case
// 2. Add it to EventToPropertiesTypes and define its properties types
//    - properties names must be in camelCase
//    - assign undefined if no properties are required
// 3. Track the event by calling Analytics.trackEvent()

export enum AnalyticsEvent {
  USER_LOGGED_IN = 'User Logged In',
  ORGANIZATION_SUMMARY_CLICK = 'Org Summarize Clicked',
  INTERACTIONS_SUMMARY_CLICK = 'Interactions Summarize Clicked',
  TAG_SUMMARY_CLICK = 'Tag Summarize Clicked',
  PERSON_SUMMARY_CLICK = 'Person Summary Clicked',
  USER_ENTERED_DIRECTORY = 'User Entered Directory',
  USER_ADDED_AN_ORGANISATION_FILTER = 'User Added An Organization Filter',
  USER_EXPORTED_DIRECTORY_TABLE_AS_CSV = 'User Exported Directory Table As CSV',
  USED_FILTERS_ON_ANALYTICS_PAGE = 'Used Filters On Analytics Page',
  CLICKED_THE_REPORTS_BUTTON = 'Clicked The Reports Button',
  NAVIGATED_TO_ANALYTICS_SCREEN = 'Navigated To Analytics Screen',
  TERM_SEARCHED = 'Term Searched',
  TAG_CATEGORY_ADDED = 'Tag Category Added',
  TAG_CATEGORY_DELETED = 'Tag Category Deleted',
  TAG_ADDED = 'Tag Added',
  TAG_DELETED = 'Tag Deleted',
  KEYWORD_ADDED = 'Keyword Added',
  KEYWORD_DELETED = 'Keyword Deleted',
  USED_BREAKDOWN_ON_AN_ANALYTICS_WIDGET = 'Used Breakdown On An Analytics Widget',
  TAG_REQUESTED = 'Tag Requested',
  TAG_SEARCH_NO_RESULTS = 'Tag Search No Results',
  POSITIVE_FEEDBACK_CLICK = 'Positive User Feedback',
  NEGATIVE_FEEDBACK_CLICK = 'Negative User Feedback',
  ENTERPRISE_SEARCH_USED = 'Enterprise Search Used',
  HIGHLIGHT_EXPLANATION_TOOLTIP_USED = 'Highlight Tooltip Used',
  HIGHLIGHT_INTERACTION_CLICK = 'Highlight Interaction Clicked',
  ES_SOURCE_TOOLTIP_USED = 'ES Tooltip Used',
  ES_SOURCE_BADGE_CLICK = 'ES Source Clicked',
  NAVIGATE_FROM_SEARCHBOX = 'Navigate From Searchbox'
}

type EventToPropertiesTypes = {
  [AnalyticsEvent.USER_LOGGED_IN]: {
    method: LoginMethod;
  };
  [AnalyticsEvent.ORGANIZATION_SUMMARY_CLICK]: {
    orgName: string;
  };
  [AnalyticsEvent.INTERACTIONS_SUMMARY_CLICK]: {
    textQuery?: string;
  };
  [AnalyticsEvent.TAG_SUMMARY_CLICK]: {
    tag: string;
  };
  [AnalyticsEvent.PERSON_SUMMARY_CLICK]: {
    person: string;
  };
  [AnalyticsEvent.USER_ENTERED_DIRECTORY]: {
  };
  [AnalyticsEvent.USER_ADDED_AN_ORGANISATION_FILTER]: {
    filter: string;
  };
  [AnalyticsEvent.USER_EXPORTED_DIRECTORY_TABLE_AS_CSV]: {
    exportEntityType: ExportEntityType;
  };
  [AnalyticsEvent.USED_FILTERS_ON_ANALYTICS_PAGE]: {
    filter: MasterFilterType;
  };
  [AnalyticsEvent.CLICKED_THE_REPORTS_BUTTON]: {
  };
  [AnalyticsEvent.NAVIGATED_TO_ANALYTICS_SCREEN]: {
    from: AnalyticsNavigationFromType;
    activeWidgets: string[];
  };
  [AnalyticsEvent.TERM_SEARCHED]: {
    term: string;
    gotResults: boolean;
  };
  [AnalyticsEvent.TAG_CATEGORY_ADDED]: {
    tagCategory: string;
  };
  [AnalyticsEvent.TAG_CATEGORY_DELETED]: {
    tagCategory: string;
  };
  [AnalyticsEvent.TAG_ADDED]: {
    tag: string;
  };
  [AnalyticsEvent.TAG_DELETED]: {
    tag: string;
  };
  [AnalyticsEvent.KEYWORD_ADDED]: {
    keyword: string;
  };
  [AnalyticsEvent.KEYWORD_DELETED]: {
    keyword: string;
  };
  [AnalyticsEvent.USED_BREAKDOWN_ON_AN_ANALYTICS_WIDGET]: {
    widgetType: WidgetType;
    breakdownType: GroupType;
  };
  [AnalyticsEvent.TAG_REQUESTED]: {
    keyword: string;
    location: RequestTagLocation;
  };
  [AnalyticsEvent.TAG_SEARCH_NO_RESULTS]: {
    keyword: string;
    location: RequestTagLocation;
  };
  [AnalyticsEvent.POSITIVE_FEEDBACK_CLICK]: {
    location: string;
    sessionId: string;
    feedbackPayload?: Record<string, unknown>;
  };
  [AnalyticsEvent.NEGATIVE_FEEDBACK_CLICK]: {
    location: string;
    sessionId: string;
    feedbackPayload?: Record<string, unknown>;
  };
  [AnalyticsEvent.ENTERPRISE_SEARCH_USED]: {
    query: string;
    sessionId: string;
    location: 'Navbar Search' | 'Feed';
  };
  [AnalyticsEvent.HIGHLIGHT_EXPLANATION_TOOLTIP_USED]: {
    highlightType: HighlightType;
    atomId: string;
    dataSource: string;
  };
  [AnalyticsEvent.HIGHLIGHT_INTERACTION_CLICK]: {
    highlightType: HighlightType;
    atomId: string;
    dataSource: string;
  };
  [AnalyticsEvent.ES_SOURCE_TOOLTIP_USED]: {
    sourceType: Source['type'];
    sourceId: string;
    dataSource?: string;
  };
  [AnalyticsEvent.ES_SOURCE_BADGE_CLICK]: {
    sourceType: Source['type'];
    sourceId: string;
    dataSource?: string;
  };
  [AnalyticsEvent.NAVIGATE_FROM_SEARCHBOX]: {
    searchBoxInput: string;
    navigatedToName: string;
    navigatedToType: 'organizations' | 'people' | 'topics';
    navigatedToLink: string;
  };
};

class Analytics {
  private static analytics: AnalyticsBrowser | undefined;

  static init() {
    if (segmentWriteKey) {
      this.analytics = AnalyticsBrowser.load({
        writeKey: segmentWriteKey,
      });
    }
  }

  static identify(user: PopulatedUserState) {
    if (segmentWriteKey && !this.analytics) {
      DebuggerConsole.error('Tried to call analytics.identify() before analytics is initialized.');
      return;
    }
    this.analytics?.identify(user.personId, {
      tenantId: user.tenantId,
      email: user.email,
      name: user.name,
      roles: user.roles,
      personaType: user.personaType,
    });
  }

  static trackEvent<K extends keyof EventToPropertiesTypes>(
    event: K,
    properties: EventToPropertiesTypes[K],
  ) {
    if (segmentWriteKey && !this.analytics) {
      DebuggerConsole.error('Tried to call analytics.track() before analytics is initialized.');
      return;
    }
    this.analytics?.track(event, properties);
  }
}

export default Analytics;

export enum MasterFilterType {
  DATES_RANGE_FILTER = 'DatesRangeFilter',
  CATEGORIES_FILTER = 'CategoriesFilter',
  PEOPLE_FILTER = 'PeopleFilter',
  DIRECTIONALITY_FILTER = 'DirectionalityFilter',
  ORGANIZATIONS_FILTER = 'OrganizationsFilter',
  APPS_FILTER = 'AppsFilter'
}

export enum AnalyticsNavigationFromType {
  NAV_BAR_BUTTON = 'navBarButton',
  DEEP_LINK_EVENT = 'deepLinkEvent',
  DEEP_LINK_OBJECTIVE = 'deepLinkObjective',
}

export function getActiveWidgets(analyticsScreen: Record<WidgetType, AnalyticsWidgetConfig>) {
  return Object.entries(analyticsScreen)
    .filter(([_, widgetConfig]) => widgetConfig.isVisible)
    .map(([widgetType]) => widgetType);
}
