import App from 'global/lists/apps';
import { AppScreen } from 'global/lists/AppScreen';
import { EventType } from 'model/Events/Event';
import { DEPARTMENT_CATEGORIES } from 'model/Tenant';
import { getUrlParamsWithVisibleWidgets } from 'screens/platform/contentScreens/AnalyticsScreen/utils/UrlParams';
import { WidgetType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { CustomEventsFiltersState } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersReducer';

type EventFilter = keyof CustomEventsFiltersState;
type ChangeType = 'increase' | 'decrease';

export interface ExternalLink {
  url: string;
  isExternal: true;
}
export interface InternalLink {
  appScreen: AppScreen;
  search?: string;
  filters: EventFilter[];
  expandDatesRange?: {
    daysToAddBefore: number;
    daysToAddAfter: number;
  };
  isExternal: false;
}
export interface ActionText {
  text: string;
  link: InternalLink | ExternalLink;
  match: {
    eventType: EventType;
    changeType?: ChangeType;
    mustInclude?: EventFilter;
    mustNotInclude?: EventFilter;
    departmentCategories?: DEPARTMENT_CATEGORIES[];
    comparedDepartmentCategories?: DEPARTMENT_CATEGORIES[];
    minSignificance?: number;
    appIds?: App[];
    minDepartmentsChange?: number[];
    maxDepartmentsChange?: number[];
  };
}

const SIGNIFICANCE_THRESHOLD = 40;
const COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD = 30;
const WEEK_IN_DAYS = 7;

/*
  @{*} will be replaced by link with * as the link text
  %{tag/department} will be replaced by tag/department label
*/
const actionTexts: ActionText[] = [
  {
    text: 'Want to know who\'s a part of the chatter? Hop on to the @{commchart} to check all people involved.',
    link: {
      appScreen: AppScreen.ANALYTICS,
      filters: ['tag', 'departments', 'datesRange'],
      isExternal: false,
      search: getUrlParamsWithVisibleWidgets([WidgetType.PARTICIPANTS]),
    },
    match: {
      eventType: EventType.CHANGE_IN_ATTENTION,
      changeType: 'increase',
    },
  },
  {
    text: 'If you expected all hands on deck, perhaps @{zoom in on their volume} to learn where their focus went.',
    link: {
      appScreen: AppScreen.ANALYTICS,
      filters: ['tag', 'departments', 'datesRange'],
      isExternal: false,
      search: getUrlParamsWithVisibleWidgets([WidgetType.PARTICIPANTS]),
    },
    match: {
      eventType: EventType.CHANGE_IN_ATTENTION,
      changeType: 'decrease',
    },
  },
  {
    text: 'Is it a @{needle-mover}? Don\'t let it slip between the cracks and get it back on your radar.',
    link: {
      url: 'https://highgrowth.com/defining-your-needle-movers/',
      isExternal: true,
    },
    match: {
      eventType: EventType.TRENDING_TOPIC,
      changeType: 'decrease',
      mustNotInclude: 'departments',
    },
  },
  {
    text: 'Missed this one? @{Subscribe to alerts on this topic} to be alerted on incoming activity',
    link: {
      appScreen: AppScreen.SLACK_ALERTS,
      filters: [],
      isExternal: false,
    },
    match: {
      eventType: EventType.TRENDING_TOPIC,
      changeType: 'increase',
      mustInclude: 'departments',
    },
  },
  {
    text: 'Time for a refresher on how to better facilitate @{business process handoffs}?',
    link: {
      url: 'https://blog.processology.net/how-to-improve-business-process-handoffs',
      isExternal: true,
    },
    match: {
      eventType: EventType.TRENDING_TOPIC,
      changeType: 'decrease',
      mustInclude: 'departments',
    },
  },
  {
    text: 'Amazing! Information is flowing. Reward your teams with a @{big shoutout on Slack}?',
    link: {
      url: 'https://slack.com/slack-tips/make-it-easy-to-give-team-shoutouts',
      isExternal: true,
    },
    match: {
      maxDepartmentsChange: [
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      minDepartmentsChange: [
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'increase',
    },
  },
  {
    text: 'Try to keep a consistent communication going across all teams with @{5 actionable tips}.',
    link: {
      url: 'https://www.grammarly.com/business/learn/improve-communication-between-departments/',
      isExternal: true,
    },
    match: {
      maxDepartmentsChange: [
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      minDepartmentsChange: [
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'decrease',
    },
  },
  {
    text: "Any new initiatives being kicked-off? @{Breakdown volume by tags} to find out what they're up to.",
    link: {
      appScreen: AppScreen.ANALYTICS,
      filters: ['departments', 'datesRange'],
      isExternal: false,
      expandDatesRange: {
        daysToAddAfter: 0,
        daysToAddBefore: WEEK_IN_DAYS,
      },
    },
    match: {
      maxDepartmentsChange: [-1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD],
      minDepartmentsChange: [COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'increase',
    },
  },
  {
    text: 'Hmmm... seems like @{other departments} are surprisingly taking up most of their time.',
    link: {
      appScreen: AppScreen.ANALYTICS,
      filters: ['datesRange'],
      isExternal: false,
      search: getUrlParamsWithVisibleWidgets([WidgetType.PARTICIPANTS]),
    },
    match: {
      minDepartmentsChange: [COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD],
      maxDepartmentsChange: [-1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'decrease',
    },
  },
  {
    text: 'Was there any shift in personnel or OOO period? Make sure @{your BCP} is in place.',
    link: {
      url: 'https://www.forbes.com/sites/forbestechcouncil/2020/05/22/three-ways-to-put-people-first-and-ensure-business-continuity-in-the-next-normal/?sh=1eccc2785f6d',
      isExternal: true,
    },
    match: {
      maxDepartmentsChange: [COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD],
      minDepartmentsChange: [
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'increase',
    },
  },
  {
    text: 'In case department was @{somewhat quiet last/this week} - keep calm and carry on.',
    link: {
      appScreen: AppScreen.ANALYTICS,
      filters: ['datesRange', 'departments'],
      isExternal: false,
      expandDatesRange: {
        daysToAddAfter: 0,
        daysToAddBefore: WEEK_IN_DAYS,
      },
    },
    match: {
      maxDepartmentsChange: [COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD],
      minDepartmentsChange: [
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'decrease',
    },
  },
  {
    text: 'Was there any shift in personnel or OOO period? Make sure @{your BCP} is in place.',
    link: {
      url: 'https://www.forbes.com/sites/forbestechcouncil/2020/05/22/three-ways-to-put-people-first-and-ensure-business-continuity-in-the-next-normal/?sh=1eccc2785f6d',
      isExternal: true,
    },
    match: {
      maxDepartmentsChange: [
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      minDepartmentsChange: [-1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'increase',
    },
  },
  {
    text: 'In case department was @{somewhat quiet last/this week} - keep calm and carry on.',
    link: {
      appScreen: AppScreen.ANALYTICS,
      filters: ['datesRange', 'departments'],
      isExternal: false,
      expandDatesRange: {
        daysToAddAfter: 0,
        daysToAddBefore: WEEK_IN_DAYS,
      },
    },
    match: {
      maxDepartmentsChange: [
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      minDepartmentsChange: [-1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'decrease',
    },
  },
  {
    text: 'Was there any shift in personnel or OOO period? Make sure @{your BCP} is in place.',
    link: {
      url: 'https://www.forbes.com/sites/forbestechcouncil/2020/05/22/three-ways-to-put-people-first-and-ensure-business-continuity-in-the-next-normal/?sh=1eccc2785f6d',
      isExternal: true,
    },
    match: {
      minDepartmentsChange: [
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'increase',
    },
  },
  {
    text: 'Was there any shift in personnel or OOO period? Make sure @{your BCP} is in place.',
    link: {
      url: 'https://www.forbes.com/sites/forbestechcouncil/2020/05/22/three-ways-to-put-people-first-and-ensure-business-continuity-in-the-next-normal/?sh=1eccc2785f6d',
      isExternal: true,
    },
    match: {
      maxDepartmentsChange: [
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'increase',
    },
  },
  {
    text: 'In case %{DEPARTMENTS} were @{somewhat quiet last/this week} - keep calm and carry on.',
    link: {
      url: 'https://www.forbes.com/sites/forbestechcouncil/2020/05/22/three-ways-to-put-people-first-and-ensure-business-continuity-in-the-next-normal/?sh=1eccc2785f6d',
      isExternal: true,
    },
    match: {
      minDepartmentsChange: [
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'decrease',
    },
  },
  {
    text: 'In case %{DEPARTMENTS} were @{somewhat quiet last/this week} - keep calm and carry on.',
    link: {
      url: 'https://www.forbes.com/sites/forbestechcouncil/2020/05/22/three-ways-to-put-people-first-and-ensure-business-continuity-in-the-next-normal/?sh=1eccc2785f6d',
      isExternal: true,
    },
    match: {
      minDepartmentsChange: [
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
        -1 * COMM_EVENT_DEPARTMENT_CHANGE_THRESHOLD,
      ],
      eventType: EventType.COMMUNICATION_FLOW,
      changeType: 'decrease',
    },
  },
  {
    text: 'Read more about how the different areas in which @{product managers need to invest their attention}.',
    link: {
      url: 'https://medium.com/productspace/attention-that-skill-for-product-managers-2b792b7855e6',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.CHANGE_IN_ATTENTION,
      departmentCategories: [DEPARTMENT_CATEGORIES.PRODUCT],
    },
  },
  {
    text: 'Developers often spend @{more than a third of their time fixing bugs}. Do you know where you stand?',
    link: {
      url: 'https://devops.com/survey-fixing-bugs-stealing-time-from-development/',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.CHANGE_IN_ATTENTION,
      departmentCategories: [DEPARTMENT_CATEGORIES.RESEARCH_AND_DEVELOPMENT],
    },
  },
  {
    text: 'Are you biting-off more than you can chew? Work with leadership to avoid @{these 15 common time wasters}.',
    link: {
      url: 'https://www.n2growth.com/leadership-and-time/',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.CHANGE_IN_ATTENTION,
      departmentCategories: [DEPARTMENT_CATEGORIES.LEADERSHIP],
    },
  },
  {
    text: 'Marketers who plan projects and follow through on them are @{4x times more likely to be successful}.',
    link: {
      url: 'https://welcomesoftware.com/insights/ultimate-guide-marketing-project-management/',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.CHANGE_IN_ATTENTION,
      departmentCategories: [DEPARTMENT_CATEGORIES.MARKETING],
    },
  },
  {
    text: 'Ever heard of the 3 Cs of customer satisfaction? @{Consistency, consistency, consistency}.',
    link: {
      url: 'https://www.mckinsey.com/industries/retail/our-insights/the-three-cs-of-customer-satisfaction-consistency-consistency-consistency',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.CHANGE_IN_ATTENTION,
      departmentCategories: [DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS],
    },
  },
  {
    text: 'Unclog your sales processes with @{conscious focus management}, at every touchpoint.',
    link: {
      url: 'https://www.pipedrive.com/en/blog/practical-steps-achieve-focus',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.CHANGE_IN_ATTENTION,
      departmentCategories: [DEPARTMENT_CATEGORIES.SALES],
    },
  },
  {
    text: 'Is %{TAG} really what matters? Rearrange priorities around @{committed OKRs for a timely delivery}.',
    link: {
      url: 'https://www.whatmatters.com/resources/google-okr-playbook',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.TRENDING_TOPIC,
      mustNotInclude: 'departments',
      changeType: 'increase',
    },
  },
  {
    text: 'Customer-facing teams need to @{work in harmony}. Start by banding them together around personas.',
    link: {
      url: 'https://www.helpscout.com/helpu/how-support-sales-marketing-work-together/',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.COMMUNICATION_FLOW,
      departmentCategories: [DEPARTMENT_CATEGORIES.MARKETING],
      comparedDepartmentCategories: [
        DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS,
        DEPARTMENT_CATEGORIES.SALES,
      ],
    },
  },
  {
    text: 'Don\'t let communication silos undermine the performance of your content, @{share your plans} with the rest of the company.',
    link: {
      url: 'https://www.clariantcreative.com/blog/4-habits-productive-marketing-teams',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.COMMUNICATION_FLOW,
      departmentCategories: [DEPARTMENT_CATEGORIES.MARKETING],
      comparedDepartmentCategories: [
        DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS,
        DEPARTMENT_CATEGORIES.SALES,
        DEPARTMENT_CATEGORIES.PRODUCT,
      ],
    },
  },
  {
    text: 'Learn more about @{what causes the partnerships} to unravel and how to strengthen them today.',
    link: {
      url: 'https://www.reforge.com/blog/cross-departmental-collaboration-product-marketing',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.COMMUNICATION_FLOW,
      departmentCategories: [DEPARTMENT_CATEGORIES.PRODUCT],
      comparedDepartmentCategories: [DEPARTMENT_CATEGORIES.MARKETING],
    },
  },
  {
    text: 'In a recent survey, over 54% of workers said @{cross department collaboration is the primary barrier for productivity}.',
    link: {
      url: 'https://www.fastcompany.com/90411686/meetings-arent-the-biggest-time-waster-at-work-this-thing-is',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.COMMUNICATION_FLOW,
    },
  },
  {
    text: 'Is your @{sales team tall or wide}? The wider your team is, the stronger the collaborative web becomes.',
    link: {
      url: 'https://www.nutshell.com/blog/tim-sanders-dealstorming-sales-collaboration',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.COMMUNICATION_FLOW,
      departmentCategories: [DEPARTMENT_CATEGORIES.SALES],
    },
  },
  {
    text: 'Working on a new feature and this activity does not match where it should be in the @{product development cycle}? Maybe it`s time to get involved',
    link: {
      url: 'https://www.netsolutions.com/insights/everything-about-new-product-development/',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.TRENDING_TOPIC,
      departmentCategories: [DEPARTMENT_CATEGORIES.RESEARCH_AND_DEVELOPMENT],
      comparedDepartmentCategories: [DEPARTMENT_CATEGORIES.PRODUCT],
    },
  },
  {
    text: 'Do you have a @{feature rollout process} in place? There`s no time like the present to start! Use Akooda to track its implementation.',
    link: {
      url: 'https://handbook.sourcegraph.com/departments/product-engineering/product/process/rollout_process/#feature-rollout-process',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.TRENDING_TOPIC,
      departmentCategories: [DEPARTMENT_CATEGORIES.MARKETING],
      comparedDepartmentCategories: [DEPARTMENT_CATEGORIES.PRODUCT],
    },
  },
  {
    text: ' @{9 out of 10 marketing and sales leaders} identify disconnects in strategy, process, content, and culture that hold back GTM efforts. How are your teams doing?',
    link: {
      url: 'https://seismic.com/enablement-explainers/what-is-marketing-enablement/',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.TRENDING_TOPIC,
      departmentCategories: [DEPARTMENT_CATEGORIES.MARKETING],
      comparedDepartmentCategories: [DEPARTMENT_CATEGORIES.SALES],
    },
  },
  {
    text: 'Where do your roadmap items come from? @{Tap into sales team insights} to create a product tends to the potential market.',
    link: {
      url: 'https://www.intercom.com/blog/why-sales-and-marketing-are-the-key-to-your-roadmap-success/',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.TRENDING_TOPIC,
      departmentCategories: [DEPARTMENT_CATEGORIES.PRODUCT],
      comparedDepartmentCategories: [DEPARTMENT_CATEGORIES.SALES],
    },
  },
  {
    text: 'Operations typically come after the core team has been set up. If you`re maturing or getting ready for scale, perhaps it`s time to @{double-down on ProductOps}.',
    link: {
      url: 'https://www.mindtheproduct.com/why-product-operations-is-set-to-be-the-backbone-of-product-led-growth/',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.TRENDING_TOPIC,
      departmentCategories: [DEPARTMENT_CATEGORIES.PRODUCT],
      comparedDepartmentCategories: [DEPARTMENT_CATEGORIES.OPERATIONS],
    },
  },
  {
    text: 'You will never achieve perfection in defining your @{Ideal Customer Profile} (highest success potential). Continue iterating as your company evolves.',
    link: {
      url: 'https://www.mykpono.com/ideal-customer-profile-icp-how-to-create-a-comprehensive-customer-profile/',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.TRENDING_TOPIC,
      departmentCategories: [DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS],
      comparedDepartmentCategories: [DEPARTMENT_CATEGORIES.OPERATIONS],
    },
  },
  {
    text: 'Ever heard of a @{“Bug Dive”}? Empower the customer success team to better resolve issues by sharing the burden of the diagnostic work with the engineering team.',
    link: {
      url: 'https://leaddev.com/cross-functional-collaboration/bridging-gap-between-engineering-and-customer-success-teams',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.TRENDING_TOPIC,
      departmentCategories: [DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS],
      comparedDepartmentCategories: [DEPARTMENT_CATEGORIES.RESEARCH_AND_DEVELOPMENT],
    },
  },
  {
    text: 'CS and product not getting along? @{Avoid common mistakes} to get them on the right path to get the customer-centric roadmap you\'re after.',
    link: {
      url: 'https://blog.nuffsaid.com/customer-success-and-product-teams',
      isExternal: true,
    },
    match: {
      minSignificance: SIGNIFICANCE_THRESHOLD,
      eventType: EventType.TRENDING_TOPIC,
      departmentCategories: [DEPARTMENT_CATEGORIES.PRODUCT],
      comparedDepartmentCategories: [DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS],
    },
  },
  {
    text: 'Cross-team collaboration is key to a successful relationship - see @{who else got involved} since the initial touchpoint.',
    link: {
      appScreen: AppScreen.ANALYTICS,
      filters: ['organizations', 'datesRange'],
      expandDatesRange: {
        daysToAddAfter: 30,
        daysToAddBefore: 0,
      },
      isExternal: false,
      search: getUrlParamsWithVisibleWidgets([WidgetType.PARTICIPANTS]),
    },
    match: {
      eventType: EventType.ORGANIZATION_FIRST_ENGAGEMENT,
    },
  },
  {
    text: 'Time for a check-up? Go to @{the directory} to see what happened since.',
    link: {
      appScreen: AppScreen.DIRECTORY,
      filters: [],
      isExternal: false,
    },
    match: {
      eventType: EventType.ORGANIZATION_LAST_ENGAGEMENT,
    },
  },
  {
    text: 'When executives assist on a deal, good things happen. Interested to see the @{before and after}.',
    link: {
      appScreen: AppScreen.ANALYTICS,
      filters: ['organizations', 'datesRange'],
      expandDatesRange: {
        daysToAddAfter: 0,
        daysToAddBefore: 14,
      },
      isExternal: false,
    },
    match: {
      eventType: EventType.ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT,
    },
  },
  {
    text: 'As a CS person, you only get one chance to make a first impression. Ever heard about the @{Halo Effect}?',
    link: {
      url: 'https://www.selfstairway.com/how-to-make-a-good-first-impression/',
      isExternal: true,
    },
    match: {
      eventType: EventType.ORGANIZATION_FIRST_ENGAGEMENT,
      minSignificance: SIGNIFICANCE_THRESHOLD,
      departmentCategories: [DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS],
      appIds: [App.SLACK],
    },
  },
  {
    text: 'Have you mastered the art of welcome emails? What do your metrics say? Check @{some industry statistics} to see where you stand.',
    link: {
      url: 'https://www.campaignmonitor.com/resources/infographics/how-effective-are-welcome-emails/',
      isExternal: true,
    },
    match: {
      eventType: EventType.ORGANIZATION_FIRST_ENGAGEMENT,
      minSignificance: SIGNIFICANCE_THRESHOLD,
      departmentCategories: [DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS],
      appIds: [App.GOOGLE_GMAIL],
    },
  },
  {
    text: 'Timeliness and speed has a direct correlation with satisfaction. What`s your team`s @{"Goldilocks Zone"} for a first response time?',
    link: {
      url: 'https://www.geckoboard.com/best-practice/kpi-examples/first-response-time/',
      isExternal: true,
    },
    match: {
      eventType: EventType.ORGANIZATION_FIRST_ENGAGEMENT,
      minSignificance: SIGNIFICANCE_THRESHOLD,
      departmentCategories: [DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS],
      appIds: [App.ATLASSIAN_JIRA, App.INTERCOM, App.ATLASSIAN],
    },
  },
  {
    text: 'How pleased are you with your onboarding call`s script? Try to @{revamp it every month} or so to keep it up to date with your product and services.',
    link: {
      url: 'https://onboard.io/blog/customer-onboarding-kickoff-call-script',
      isExternal: true,
    },
    match: {
      eventType: EventType.ORGANIZATION_FIRST_ENGAGEMENT,
      minSignificance: SIGNIFICANCE_THRESHOLD,
      departmentCategories: [DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS],
      appIds: [App.GOOGLE_CALENDAR],
    },
  },
  {
    text: 'Nobody likes it when customers ghost them. @{Map out the reasons} why that is and craft a message that resonates.',
    link: {
      url: 'https://sixteenventures.com/why-customers-ghost',
      isExternal: true,
    },
    match: {
      eventType: EventType.ORGANIZATION_LAST_ENGAGEMENT,
      minSignificance: SIGNIFICANCE_THRESHOLD,
      departmentCategories: [DEPARTMENT_CATEGORIES.CUSTOMER_SUCCESS],
    },
  },
  {
    text: 'Hearing crickets? Just before you marking them as a Closed-Lost, consider @{trying a re-engagement tactic}.',
    link: {
      url: 'https://www.titangrowth.com/blog/ghosted-4-ways-to-re-engage-your-ghost-leads/',
      isExternal: true,
    },
    match: {
      eventType: EventType.ORGANIZATION_LAST_ENGAGEMENT,
      minSignificance: SIGNIFICANCE_THRESHOLD,
      departmentCategories: [DEPARTMENT_CATEGORIES.SALES],
    },
  },
];

export default actionTexts;
