import classNames from 'classnames';
import React, { useMemo } from 'react';

import Feedback from 'common-ui-components/Feedback';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import useInteractionsSummaryFetching
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/InteractionsSummaryFetchingHook';
import InteractionsSummaryMetadata
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/InteractionsSummaryMetadata';
import TypeWriter
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/TypeWriter';
import {
  InteractionsTableState,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';
import TextLoader from 'screens/platform/cross-platform-components/TextLoader';
import { useMountedState } from 'utils/hooks';

import style
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/style.module.scss';

function useTypingIndicator() {
  const [isTypingFinished, setIsTypingFinished] = useMountedState(false);
  function onFinishTyping() {
    setIsTypingFinished(true);
  }
  return { isTypingFinished, onFinishTyping };
}

export default function InteractionsSummary() {
  const { data, loading } = useInteractionsSummaryFetching();

  const { interactionsTable } = usePlatformContext();

  const { isTypingFinished, onFinishTyping } = useTypingIndicator();

  const isClosed = interactionsTable.openState === InteractionsTableState.CLOSED;

  const content = useMemo(() => (loading || data === undefined
    ? <TextLoader />
    : <TypeWriter text={data.summary} onFinishTyping={onFinishTyping} />), [data, loading]);

  const feedback = useMemo(
    () => (isTypingFinished && data !== undefined
      ? <Feedback sessionId={data.sessionId} location="Interactions Summary" /> : null),
    [data?.sessionId, isTypingFinished],
  );

  return (
    <div className={classNames(
      style.interactionsSummary,
      isClosed && style.fullHeight,
      isClosed && style.hidden,
    )}
    >
      <div className={style.content}>
        {content}
        {feedback}
      </div>
      <InteractionsSummaryMetadata
        data={data}
        loading={loading}
      />
    </div>
  );
}
