import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Role from 'global/lists/Role';
import { getScreenPath } from 'global/ScreensConfiguration';
import NotFoundPage from 'routers/NotFoundPage';
import RedirectToAnalytics from 'screens/platform/contentScreens/AnalyticsScreen/components/RedirectToAnalytics';
import { WidgetType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import LazyRoute from 'screens/platform/cross-platform-components/LazyRoute';
import lazyWithRetry from 'utils/lazyWithRetry';

const AnalyticsScreen = lazyWithRetry(() => import('screens/platform/contentScreens/AnalyticsScreen'));
const DirectoryRouter = lazyWithRetry(() => import('routers/DirectoryRouter'));
const ReportsRouter = lazyWithRetry(() => import('routers/ReportsRouter'));

const platformPath = `${getScreenPath('platform')}/`;

function PlatformContentRouter() {
  return (
    <Switch>
      <LazyRoute
        path={getScreenPath('platform.analytics')}
        component={<AnalyticsScreen />}
        minimumRole={Role.ANALYST}
      />
      {/* commChart and explore routes are here for legacy links support  */}
      <LazyRoute
        path={getScreenPath('platform.commChart')}
        component={
          <RedirectToAnalytics visibleWidgets={[WidgetType.PARTICIPANTS]} />
        }
        minimumRole={Role.ANALYST}
      />
      <LazyRoute
        path={getScreenPath('platform.explore')}
        component={
          <RedirectToAnalytics />
        }
        minimumRole={Role.ANALYST}
      />
      <LazyRoute
        path={getScreenPath('platform.directory')}
        component={<DirectoryRouter />}
      />
      <LazyRoute
        path={getScreenPath('platform.reports')}
        component={<ReportsRouter />}
      />
      <Route path={`${platformPath}*`}>
        <NotFoundPage padded />
      </Route>
    </Switch>
  );
}

export default PlatformContentRouter;
