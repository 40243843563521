import classNames from 'classnames';
import React, { Suspense } from 'react';

import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import CollapsibleInteractionsDrawer, {
  InteractionsTableState,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';
import lazyWithRetry from 'utils/lazyWithRetry';

import style from 'screens/platform/cross-platform-components/Panels/BottomPanel/bottomPanelStyle.module.scss';

const InfoPanel = lazyWithRetry(() => import('screens/platform/cross-platform-components/Panels/BottomPanel/InfoPanel'));

export default function BottomPanel() {
  const { currentlyDraggedNode, interactionsTable } = usePlatformContext();

  const isOpened = interactionsTable.openState === InteractionsTableState.OPENED;
  const isHalfOpened = interactionsTable.openState === InteractionsTableState.HALF_OPENED;

  return (
    <div
      className={classNames(style.bottomPanel, {
        [style.transparent]: currentlyDraggedNode !== null,
        [style.opened]: isOpened,
        [style.halfOpened]: isHalfOpened,
      })}
    >
      {interactionsTable.openState !== InteractionsTableState.OPENED && (
        <Suspense fallback={null}>
          <InfoPanel />
        </Suspense>
      )}
      <CollapsibleInteractionsDrawer />
    </div>
  );
}
